import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { format } from "date-fns-tz";

import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { useDeliveryStore, useNavStore } from "../stores/hooks";

import { STATUS, getEstimatedTime } from "../utils/common";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen &&
    css`
      position: fixed;
      width: 100%;
      height: 100vh;
    `}

  ${({ submitted }) =>
    submitted &&
    css`
      ${BgTriangle} {
        &:before {
          border-right: 100vw solid #000;
        }
      }

      ${SectionTitle} {
        background-color: #000;
      }

      ${SectionForm} {
        background-color: #000;
      }

      ${SectionResult} {
        display: block;
      }
    `};
`;
const BgTriangle = styled.div`
  width: 100%;
  height: 370px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 70px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 300px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const SectionTitle = styled.div``;
const PreTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 12px;
`;
const Desc = styled.div`
  padding-bottom: 23px;
  position: relative;
`;
const DescImage = styled.img`
  height: 128px;
  position: absolute;
  bottom: 10px;
  right: -35px;

  @media (max-width: 335px) {
    height: 110px;
  }
`;

const SectionForm = styled.div``;
const CheckInputGroup = styled.div`
  position: relative;
  font-size: 0;
  margin-bottom: 16px;

  ${({ filled }) =>
    filled &&
    css`
      ${CheckInputIcon} {
        display: block;
        background-image: url(/assets/images/input-del.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    `}

  ${({ correct }) =>
    correct &&
    css`
      ${CheckInput} {
        border-bottom: 1px solid #75ff6f;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      ${CheckInput} {
        border-bottom: 1px solid #ea2845;
      }
      ${CheckInputIcon} {
        display: block;
        background-image: url(/assets/images/input-error.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    `}
`;
const CheckInput = styled.input`
  width: 100%;
  height: 50px;
  background-color: #333;
  font-size: 16px;
  color: #fff;
  border: none;
  border-bottom: 1px solid #999;
  padding: 10px;
  outline: none;

  &:active,
  &:hover,
  &:focus {
    border-bottom: 1px solid #75ff6f;
  }
`;
const CheckInputIcon = styled.div`
  display: none;
  position: absolute;
  right: 9px;
  top: 9px;
  width: 32px;
  height: 32px;
`;
const CheckButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #75ff6f;
  font-weight: bold;
  color: #202020;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const SectionResult = styled.div`
  display: none;
  background-color: #000;
  padding-top: 24px;
  padding-bottom: 25px;
`;
const ResultHighlight = styled.div`
  color: #75ff6f;
  padding-bottom: 24px;
`;
const Bold = styled.span`
  font-weight: bold;
`;
const ResultDelivery = styled.div`
  padding-bottom: 16px;
`;

const ResultStep = styled.div`
  border-top: 1px solid #333;
  padding-top: 16px;
`;
const ResultStepItem = styled.div`
  color: #999;

  ${({ active }) => active && "color: #75ff6f;"};
`;

const ResultStepCancel = styled.span`
  color: #fff;
`;

const SectionNotice = styled.div`
  color: #999999;
  padding-top: 24px;
  padding-bottom: 40px;
`;

const SectionBanner = styled.div``;

function Index() {
  const { item } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { navActive } = useNavStore().state;

  const [bookId, setBookId] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const fetchById = useCallback(
    async ({ bookId }) => {
      try {
        await actions.getDelivery({ bookId: bookId.trim() });
      } catch (err) {
        console.log(err);
      }
    },
    [actions],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBookId = urlParams.get("bookId");

    if (!loaded && paramsBookId) {
      setBookId(paramsBookId);

      setSubmitted(true);
      fetchById({ bookId: paramsBookId });

      setLoaded(true);
    }
  }, [loaded, fetchById]);

  const handleChange = useCallback((e) => {
    const _bookId = e.target.value;

    const text = /[R0-9]/g;
    const validedText = _bookId.match(text);

    if (!_bookId) {
      setSubmitted(false);
      setBookId("");
    } else if (validedText) {
      setBookId(validedText.join(""));
      setSubmitted(false);
    }
  }, []);

  const reset = useCallback(() => {
    setBookId("");
    setSubmitted(false);
  }, []);

  const submit = useCallback(() => {
    setSubmitted(true);

    if (bookId) {
      fetchById({ bookId });
    }
  }, [bookId, fetchById]);

  // 신청시간
  const receiptDate =
    item && item.receiptDate ? new Date(item.receiptDate) : null;

  // 수거지정 시간
  const pickupDateScheduled =
    item && item.pickupDateScheduled
      ? new Date(item.pickupDateScheduled)
      : null;

  // 수거완료 시간
  const pickupDateCompleted =
    item && item.pickupDateCompleted
      ? new Date(item.pickupDateCompleted)
      : null;

  // 입고시간
  const warehousedAt =
    item && item.warehousedAt ? new Date(item.warehousedAt) : null;

  // 출고시간
  const releasedAt = item && item.releasedAt ? new Date(item.releasedAt) : null;

  // 배송완료 시간
  const deliveryCompletedDate =
    item && item.deliveryCompletedDate
      ? new Date(item.deliveryCompletedDate)
      : null;

  // 반송완료 시간
  const sentBackDate =
    item && item.sentBackDate ? new Date(item.sentBackDate) : null;

  return (
    <BodyContent navOpen={navActive} submitted={submitted}>
      <BgTriangle />
      <Nav />
      <SectionTitle>
        <Container>
          <PreTitle>4시간 오늘도착 배송혁명</PreTitle>
          <Title>
            지금어디?
            <br />
            배송조회
          </Title>
          <Desc>
            오늘 사고, 오늘 받는
            <br />
            가장 빠른 배송! 두발히어로!
            <br />
            문자/알림톡으로 받은
            <br />
            접수번호를 입력해주세요.
            <DescImage src="/assets/images/box.png" alt="박스 이미지" />
          </Desc>
        </Container>
      </SectionTitle>
      <SectionForm>
        <Container>
          <CheckInputGroup
            correct={bookId && submitted}
            error={!bookId && submitted}
            filled={bookId}
          >
            <CheckInput
              type="text"
              placeholder="접수번호"
              onChange={handleChange}
              value={bookId}
            />
            <CheckInputIcon onClick={reset} />
          </CheckInputGroup>
          <CheckButton type="button" onClick={submit}>
            배송조회
          </CheckButton>
        </Container>
      </SectionForm>
      {submitted && (
        <SectionResult>
          <Container>
            {item ? (
              <ResultHighlight>
                배송조회 결과:
                <br />
                <Bold>
                  {item.status === 0 && receiptDate
                    ? `${format(receiptDate, "H시m분")}` // 신청
                    : item.status === 1 && pickupDateScheduled
                    ? `${format(pickupDateScheduled, "H시m분")}` // 수거지정
                    : (item.status === 2 || item.status === 3) &&
                      pickupDateCompleted
                    ? `${format(pickupDateCompleted, "H시m분")}` // 수거 완료
                    : // : item.status === 3 && warehousedAt
                    // ? `${format(warehousedAt, "H시m분")}` // 입고
                    item.status === 4 && releasedAt
                    ? `${format(releasedAt, "H시m분")}` // 출고
                    : item.status === 5 && deliveryCompletedDate
                    ? `${format(deliveryCompletedDate, "H시m분")}` // 배송완료
                    : item.status === 6 && sentBackDate
                    ? `${format(sentBackDate, "H시m분")}` // 반송완료시간
                    : ""}
                </Bold>
                에 {STATUS[item.status]}되었습니다.
                {item.status === 4 && !item.isCanceled && !item.isAccident ? (
                  <>
                    <br />
                    <br />
                    담당 배송기사님이 남은 {item.deliveriesCountLeftYet ||
                      0}{" "}
                    건의 배송을 진행하고 있습니다. 최대한 빠르고 정확하게
                    배송하겠습니다.
                    <br />
                    <br />
                    도착예상시간:{" "}
                    {item.order && item.order > 0
                      ? getEstimatedTime(item.order)
                      : "-"}
                    <br />
                    ※교통 및 기상상황에 따라 예상 도착 시간이 ±1시간 정도 차이가
                    날 수 있습니다.
                  </>
                ) : (
                  ""
                )}
              </ResultHighlight>
            ) : (
              <ResultHighlight>
                배송조회 결과:
                <br />
                조회된 정보가 없습니다.
              </ResultHighlight>
            )}
            {item && item.isCanceled ? (
              <ResultDelivery>
                취소사유:
                <br />
                {item.canceledReason}(
                {format(new Date(item.canceledAt), "M월 d일 H:m")})
              </ResultDelivery>
            ) : (
              ""
            )}
            {item && item.sentBackLogs && item.sentBackLogs.length ? (
              <ResultDelivery>
                반송사유:
                <br />
                {item.sentBackLogs.map((sl) => (
                  <div key={sl.id}>
                    {sl.reason}({format(new Date(sl.createdAt), "M월 d일 H:m")})
                  </div>
                ))}
              </ResultDelivery>
            ) : (
              ""
            )}
            {item && item.delayedDeliveries && item.delayedDeliveries.length ? (
              <ResultDelivery>
                미배송사유:
                <br />
                {item.delayedDeliveries.map((dd) => (
                  <div key={dd.id}>
                    {dd.reason}({format(new Date(dd.createdAt), "M월 d일 H:m")})
                  </div>
                ))}
              </ResultDelivery>
            ) : (
              ""
            )}
            <ResultDelivery>
              접수처: {item ? item.senderName : "-"}
              <br />
              도착지:{" "}
              {item ? `${item.receiverName} ${item.receiverMobile || ""}` : "-"}
              <br />
              {item && item.receiverAddressRoad}
            </ResultDelivery>
            <ResultStep>
              <ResultStepItem active={item && item.status === 0}>
                ① 접수{" "}
                {item && receiptDate
                  ? `${format(receiptDate, "M월 d일 H시 m분")} 완료`
                  : "-"}
                <ResultStepCancel>
                  {item &&
                  (item.status === 0 || item.status === 1) &&
                  item.isCanceled
                    ? "(취소됨)"
                    : ""}
                </ResultStepCancel>
              </ResultStepItem>
              <ResultStepItem active={item && item.status === 1}>
                ② 수거{" "}
                {item
                  ? item.status === 1
                    ? "진행 중"
                    : item.status > 1 && pickupDateCompleted
                    ? `${format(pickupDateCompleted, "M월 d일 H시 m분")} 완료`
                    : "-"
                  : "-"}
                <ResultStepCancel>
                  {item &&
                  (item.status === 1 || item.status === 2) &&
                  item.isCanceled
                    ? "(취소됨)"
                    : ""}
                </ResultStepCancel>
              </ResultStepItem>
              <ResultStepItem
                active={item && (item.status === 2 || item.status === 3)}
              >
                ③ 입고{" "}
                {item
                  ? item.status === 2 || item.status === 3 || item.status === 8
                    ? "진행 중"
                    : item.status > 3 && warehousedAt
                    ? `${format(warehousedAt, "M월 d일 H시 m분")} 완료`
                    : "-"
                  : "-"}
                <ResultStepCancel>
                  {item &&
                  (item.status === 2 || item.status === 3) &&
                  item.isCanceled
                    ? "(취소됨)"
                    : ""}
                </ResultStepCancel>
              </ResultStepItem>
              <ResultStepItem>
                ④ 출고{" "}
                {item
                  ? item.status > 3 && releasedAt
                    ? `${format(releasedAt, "M월 d일 H시 m분")} 완료`
                    : "-"
                  : "-"}
                <ResultStepCancel>
                  {item &&
                  (item.status === 3 || item.status === 4) &&
                  item.isCanceled
                    ? "(취소됨)"
                    : ""}
                </ResultStepCancel>
              </ResultStepItem>
              {item && item.status === 6 ? (
                <ResultStepItem active={item && item.status === 6}>
                  ⑤ 반송 {format(sentBackDate, "M월 d일 H시 m분")} 완료
                </ResultStepItem>
              ) : (
                <ResultStepItem
                  active={item && (item.status === 4 || item.status === 5)}
                >
                  ⑤ 도착{" "}
                  {item
                    ? item.status === 4
                      ? "배송 진행 중"
                      : item.status > 4 && deliveryCompletedDate
                      ? `${format(
                          deliveryCompletedDate,
                          "M월 d일 H시 m분",
                        )} 완료`
                      : "-"
                    : "-"}
                  <ResultStepCancel>
                    {item &&
                    (item.status === 4 ||
                      item.status === 5 ||
                      item.status === 6) &&
                    item.isCanceled
                      ? "(취소됨)"
                      : ""}
                  </ResultStepCancel>
                </ResultStepItem>
              )}
            </ResultStep>
          </Container>
        </SectionResult>
      )}
      <SectionNotice>
        <Container>
          두발히어로 배송기사님이 빠르고 안전하게 배송하고 있습니다.
          <br />
          배송 관련하여 문의사항이 있으시면 카카오톡 1:1 상담에 접수번호, 성함,
          연락처와 함께 남겨주십시오.
          <br />
          빠르게 문의내용 확인하고 답변드리겠습니다.
          <br />
          *기상상황, 교통상황에 따라 예상보다 배송이 늦어질 수 있습니다.
          <br />
          <br />
          <Link
            to={{
              pathname: "http://pf.kakao.com/_GxeFxnxb/chat",
            }}
            target="_blank"
          >
            카카오톡 1:1 상담(터치하세요)
          </Link>
          <br />
          배송문의이메일: check@chainlogis.com
        </Container>
      </SectionNotice>
      <SectionBanner>
        <Container>
          <Banner />
        </Container>
      </SectionBanner>
      <Footer />
    </BodyContent>
  );
}

export default Index;
